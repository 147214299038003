<template>
  <section
    id="photo-gallery"
  >
    <base-section-heading
      :title="$t('PhotoGallery.title')"
    />

    <v-responsive
      class="d-flex"
      align="center"
    >
      <v-col
        md="9"
        sm="12"
      >
        <v-carousel
          hide-delimiters
          height="auto"
        >
          <v-carousel-item
            v-for="(photo, i) in fieldPhotos"
            :key="i"
          >
            <v-img
              :lazy-src="require(`@/assets/small/${photo.fileName}`)"
              :src="require(`@/assets/${photo.fileName}`)"
              aspect-ratio="1.77"
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-responsive>
  </section>
</template>

<script>
  import photos from '@/photos.json'
  export default {
    name: 'PhotoGallery',
    fieldPhotos: null,
    specificField: null,
    created () {
      const specificField = this.$route.meta.option
      const photoArray = []
      photos.forEach(photo => {
        if (photo.type === specificField) {
          photoArray.push(photo)
        }
      })
      this.fieldPhotos = photoArray.sort(() => Math.random() - 0.5)
    },
  }
</script>
